<template>
    <div v-if="odortypesearchViewActive" class="search">
        <div class="table-box" style="width: 1300px; overflow-x: scroll; ">
            <div class="el-table--fit" style="width: max-content;">
                <el-table :data="tableData" class="el-table__header" tooltip-effect="dark">
                    <el-table-column label="ID" prop="num" min-width="100">
                        <template slot-scope="scope">
                            {{ ((pageinfo.page - 1) * pageinfo.size) + (scope.$index + 1) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="CID" prop="CID" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.CID}`" placement="top-start">
                                <div slot="content">{{ scope.row.CID }}</div>
                                <div class="btn">{{ scope.row.CID }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="IsomericSMILES" prop="IsomericSMILES" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.IsomericSMILES}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.IsomericSMILES }}</div>
                                <div class="btn">{{ scope.row.IsomericSMILES }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="CSL_Database_OdorType" prop="CSL_Database_OdorType" min-width="250">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.CSL_Database_OdorType}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.CSL_Database_OdorType }}</div>
                                <div class="btn">{{ scope.row.CSL_Database_OdorType }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="alcoholic" prop="alcoholic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.alcoholic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.alcoholic }}</div>
                                <div class="btn">{{ scope.row.alcoholic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="aldehydic" prop="aldehydic" min-width="130">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.aldehydic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.aldehydic }}</div>
                                <div class="btn">{{ scope.row.aldehydic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="alliaceous" prop="alliaceous" min-width="130">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.alliaceous}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.alliaceous }}</div>
                                <div class="btn">{{ scope.row.alliaceous }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="almond" prop="almond" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.almond}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.almond }}</div>
                                <div class="btn">{{ scope.row.almond }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="amber" prop="amber" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.amber}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.amber }}</div>
                                <div class="btn">{{ scope.row.amber }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="animal" prop="animal" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.animal}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.animal }}</div>
                                <div class="btn">{{ scope.row.animal }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="anisic" prop="anisic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.anisic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.anisic }}</div>
                                <div class="btn">{{ scope.row.anisic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="apple" prop="apple" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.apple}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.apple }}</div>
                                <div class="btn">{{ scope.row.apple }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="apricot" prop="apricot" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.apricot}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.apricot }}</div>
                                <div class="btn">{{ scope.row.apricot }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="aromatic" prop="aromatic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.aromatic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.aromatic }}</div>
                                <div class="btn">{{ scope.row.aromatic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="balsamic" prop="balsamic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.balsamic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.balsamic }}</div>
                                <div class="btn">{{ scope.row.balsamic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="banana" prop="banana" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.banana}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.banana }}</div>
                                <div class="btn">{{ scope.row.banana }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="beefy" prop="beefy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.beefy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.beefy }}</div>
                                <div class="btn">{{ scope.row.beefy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="bergamot" prop="bergamot" min-width="130">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.bergamot}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.bergamot }}</div>
                                <div class="btn">{{ scope.row.bergamot }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="berry" prop="berry" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.berry}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.berry }}</div>
                                <div class="btn">{{ scope.row.berry }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="bitter" prop="bitter" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.bitter}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.bitter }}</div>
                                <div class="btn">{{ scope.row.bitter }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="black_currant" prop="black_currant" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.black_currant}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.black_currant }}</div>
                                <div class="btn">{{ scope.row.black_currant }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="brandy" prop="brandy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.brandy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.brandy }}</div>
                                <div class="btn">{{ scope.row.brandy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="burnt" prop="burnt" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.burnt}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.burnt }}</div>
                                <div class="btn">{{ scope.row.burnt }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="buttery" prop="buttery" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.buttery}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.buttery }}</div>
                                <div class="btn">{{ scope.row.buttery }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cabbage" prop="cabbage" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cabbage}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cabbage }}</div>
                                <div class="btn">{{ scope.row.cabbage }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="camphoreous" prop="camphoreous" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.camphoreous}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.camphoreous }}</div>
                                <div class="btn">{{ scope.row.camphoreous }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="caramellic" prop="caramellic" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.caramellic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.caramellic }}</div>
                                <div class="btn">{{ scope.row.caramellic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cedar" prop="cedar" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cedar}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cedar }}</div>
                                <div class="btn">{{ scope.row.cedar }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="celery" prop="celery" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.celery}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.celery }}</div>
                                <div class="btn">{{ scope.row.celery }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="chamomile" prop="chamomile" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.chamomile}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.chamomile }}</div>
                                <div class="btn">{{ scope.row.chamomile }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cheesy" prop="cheesy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cheesy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cheesy }}</div>
                                <div class="btn">{{ scope.row.cheesy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cherry" prop="cherry" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cherry}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cherry }}</div>
                                <div class="btn">{{ scope.row.cherry }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="chocolate" prop="chocolate" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.chocolate}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.chocolate }}</div>
                                <div class="btn">{{ scope.row.chocolate }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cinnamon" prop="cinnamon" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cinnamon}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cinnamon }}</div>
                                <div class="btn">{{ scope.row.cinnamon }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="citrus" prop="citrus" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.citrus}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.citrus }}</div>
                                <div class="btn">{{ scope.row.citrus }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="clean" prop="clean" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.clean}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.clean }}</div>
                                <div class="btn">{{ scope.row.clean }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="clove" prop="clove" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.clove}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.clove }}</div>
                                <div class="btn">{{ scope.row.clove }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cocoa" prop="cocoa" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cocoa}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cocoa }}</div>
                                <div class="btn">{{ scope.row.cocoa }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="coconut" prop="coconut" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.coconut}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.coconut }}</div>
                                <div class="btn">{{ scope.row.coconut }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="coffee" prop="coffee" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.coffee}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.coffee }}</div>
                                <div class="btn">{{ scope.row.coffee }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cognac" prop="cognac" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cognac}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cognac }}</div>
                                <div class="btn">{{ scope.row.cognac }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cooked" prop="cooked" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cooked}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cooked }}</div>
                                <div class="btn">{{ scope.row.cooked }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cooling" prop="cooling" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cooling}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cooling }}</div>
                                <div class="btn">{{ scope.row.cooling }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cortex" prop="cortex" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cortex}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cortex }}</div>
                                <div class="btn">{{ scope.row.cortex }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="coumarinic" prop="coumarinic" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.coumarinic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.coumarinic }}</div>
                                <div class="btn">{{ scope.row.coumarinic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="creamy" prop="creamy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.creamy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.creamy }}</div>
                                <div class="btn">{{ scope.row.creamy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="cucumber" prop="cucumber" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.cucumber}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.cucumber }}</div>
                                <div class="btn">{{ scope.row.cucumber }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="dairy" prop="dairy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.dairy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.dairy }}</div>
                                <div class="btn">{{ scope.row.dairy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="dry" prop="dry" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.dry}`" placement="top-start">
                                <div slot="content">{{ scope.row.dry }}</div>
                                <div class="btn">{{ scope.row.dry }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="earthy" prop="earthy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.earthy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.earthy }}</div>
                                <div class="btn">{{ scope.row.earthy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="ethereal" prop="ethereal" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.ethereal}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.ethereal }}</div>
                                <div class="btn">{{ scope.row.ethereal }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="fatty" prop="fatty" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.fatty}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.fatty }}</div>
                                <div class="btn">{{ scope.row.fatty }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="fermented" prop="fermented" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.fermented}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.fermented }}</div>
                                <div class="btn">{{ scope.row.fermented }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="fishy" prop="fishy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.fishy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.fishy }}</div>
                                <div class="btn">{{ scope.row.fishy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="floral" prop="floral" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.floral}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.floral }}</div>
                                <div class="btn">{{ scope.row.floral }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="fresh" prop="fresh" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.fresh}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.fresh }}</div>
                                <div class="btn">{{ scope.row.fresh }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="fruit_skin" prop="fruit_skin" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.fruit_skin}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.fruit_skin }}</div>
                                <div class="btn">{{ scope.row.fruit_skin }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="fruity" prop="fruity" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.fruity}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.fruity }}</div>
                                <div class="btn">{{ scope.row.fruity }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="garlic" prop="garlic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.garlic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.garlic }}</div>
                                <div class="btn">{{ scope.row.garlic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="gassy" prop="gassy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.gassy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.gassy }}</div>
                                <div class="btn">{{ scope.row.gassy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="geranium" prop="geranium" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.geranium}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.geranium }}</div>
                                <div class="btn">{{ scope.row.geranium }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="grape" prop="grape" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.grape}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.grape }}</div>
                                <div class="btn">{{ scope.row.grape }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="grapefruit" prop="grapefruit" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.grapefruit}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.grapefruit }}</div>
                                <div class="btn">{{ scope.row.grapefruit }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="grassy" prop="grassy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.grassy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.grassy }}</div>
                                <div class="btn">{{ scope.row.grassy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="green" prop="green" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.green}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.green }}</div>
                                <div class="btn">{{ scope.row.green }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="hawthorn" prop="hawthorn" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.hawthorn}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.hawthorn }}</div>
                                <div class="btn">{{ scope.row.hawthorn }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="hay" prop="hay" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.hay}`" placement="top-start">
                                <div slot="content">{{ scope.row.hay }}</div>
                                <div class="btn">{{ scope.row.hay }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="hazelnut" prop="hazelnut" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.hazelnut}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.hazelnut }}</div>
                                <div class="btn">{{ scope.row.hazelnut }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="herbal" prop="herbal" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.herbal}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.herbal }}</div>
                                <div class="btn">{{ scope.row.herbal }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="honey" prop="honey" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.honey}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.honey }}</div>
                                <div class="btn">{{ scope.row.honey }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="hyacinth" prop="hyacinth" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.hyacinth}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.hyacinth }}</div>
                                <div class="btn">{{ scope.row.hyacinth }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="jasmin" prop="jasmin" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.jasmin}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.jasmin }}</div>
                                <div class="btn">{{ scope.row.jasmin }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="juicy" prop="juicy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.juicy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.juicy }}</div>
                                <div class="btn">{{ scope.row.juicy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="ketonic" prop="ketonic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.ketonic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.ketonic }}</div>
                                <div class="btn">{{ scope.row.ketonic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="lactonic" prop="lactonic" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.lactonic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.lactonic }}</div>
                                <div class="btn">{{ scope.row.lactonic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="lavender" prop="lavender" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.lavender}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.lavender }}</div>
                                <div class="btn">{{ scope.row.lavender }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="leafy" prop="leafy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.leafy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.leafy }}</div>
                                <div class="btn">{{ scope.row.leafy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="leathery" prop="leathery" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.leathery}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.leathery }}</div>
                                <div class="btn">{{ scope.row.leathery }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="lemon" prop="lemon" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.lemon}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.lemon }}</div>
                                <div class="btn">{{ scope.row.lemon }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="lily" prop="lily" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.lily}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.lily }}</div>
                                <div class="btn">{{ scope.row.lily }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="malty" prop="malty" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.malty}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.malty }}</div>
                                <div class="btn">{{ scope.row.malty }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="meaty" prop="meaty" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.meaty}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.meaty }}</div>
                                <div class="btn">{{ scope.row.meaty }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="medicinal" prop="medicinal" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.medicinal}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.medicinal }}</div>
                                <div class="btn">{{ scope.row.medicinal }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="melon" prop="melon" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.melon}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.melon }}</div>
                                <div class="btn">{{ scope.row.melon }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="metallic" prop="metallic" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.metallic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.metallic }}</div>
                                <div class="btn">{{ scope.row.metallic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="milky" prop="milky" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.milky}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.milky }}</div>
                                <div class="btn">{{ scope.row.milky }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="mint" prop="mint" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.mint}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.mint }}</div>
                                <div class="btn">{{ scope.row.mint }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="muguet" prop="muguet" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.muguet}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.muguet }}</div>
                                <div class="btn">{{ scope.row.muguet }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="mushroom" prop="mushroom" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.mushroom}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.mushroom }}</div>
                                <div class="btn">{{ scope.row.mushroom }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="musk" prop="musk" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.musk}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.musk }}</div>
                                <div class="btn">{{ scope.row.musk }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="musty" prop="musty" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.musty}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.musty }}</div>
                                <div class="btn">{{ scope.row.musty }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="natural" prop="natural" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.natural}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.natural }}</div>
                                <div class="btn">{{ scope.row.natural }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="nutty" prop="nutty" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.nutty}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.nutty }}</div>
                                <div class="btn">{{ scope.row.nutty }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="odorless" prop="odorless" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.odorless}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.odorless }}</div>
                                <div class="btn">{{ scope.row.odorless }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="oily" prop="oily" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.oily}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.oily }}</div>
                                <div class="btn">{{ scope.row.oily }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="onion" prop="onion" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.onion}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.onion }}</div>
                                <div class="btn">{{ scope.row.onion }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="orange" prop="orange" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.orange}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.orange }}</div>
                                <div class="btn">{{ scope.row.orange }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="orangeflower" prop="orangeflower" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.orangeflower}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.orangeflower }}</div>
                                <div class="btn">{{ scope.row.orangeflower }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="orris" prop="orris" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.orris}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.orris }}</div>
                                <div class="btn">{{ scope.row.orris }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="ozone" prop="ozone" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.ozone}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.ozone }}</div>
                                <div class="btn">{{ scope.row.ozone }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="peach" prop="peach" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.peach}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.peach }}</div>
                                <div class="btn">{{ scope.row.peach }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="pear" prop="pear" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.pear}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.pear }}</div>
                                <div class="btn">{{ scope.row.pear }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="phenolic" prop="phenolic" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.phenolic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.phenolic }}</div>
                                <div class="btn">{{ scope.row.phenolic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="pine" prop="pine" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.pine}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.pine }}</div>
                                <div class="btn">{{ scope.row.pine }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="pineapple" prop="pineapple" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.pineapple}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.pineapple }}</div>
                                <div class="btn">{{ scope.row.pineapple }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="plum" prop="plum" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.plum}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.plum }}</div>
                                <div class="btn">{{ scope.row.plum }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="popcorn" prop="popcorn" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.popcorn}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.popcorn }}</div>
                                <div class="btn">{{ scope.row.popcorn }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="potato" prop="potato" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.potato}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.potato }}</div>
                                <div class="btn">{{ scope.row.potato }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="powdery" prop="powdery" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.powdery}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.powdery }}</div>
                                <div class="btn">{{ scope.row.powdery }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="pungent" prop="pungent" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.pungent}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.pungent }}</div>
                                <div class="btn">{{ scope.row.pungent }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="radish" prop="radish" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.radish}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.radish }}</div>
                                <div class="btn">{{ scope.row.radish }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="raspberry" prop="raspberry" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.raspberry}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.raspberry }}</div>
                                <div class="btn">{{ scope.row.raspberry }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="ripe" prop="ripe" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.ripe}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.ripe }}</div>
                                <div class="btn">{{ scope.row.ripe }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="roasted" prop="roasted" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.roasted}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.roasted }}</div>
                                <div class="btn">{{ scope.row.roasted }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="rose" prop="rose" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.rose}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.rose }}</div>
                                <div class="btn">{{ scope.row.rose }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="rummy" prop="rummy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.rummy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.rummy }}</div>
                                <div class="btn">{{ scope.row.rummy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="sandalwood" prop="sandalwood" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.sandalwood}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.sandalwood }}</div>
                                <div class="btn">{{ scope.row.sandalwood }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="savory" prop="savory" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.savory}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.savory }}</div>
                                <div class="btn">{{ scope.row.savory }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="sharp" prop="sharp" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.sharp}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.sharp }}</div>
                                <div class="btn">{{ scope.row.sharp }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="smoky" prop="smoky" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.smoky}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.smoky }}</div>
                                <div class="btn">{{ scope.row.smoky }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="soapy" prop="soapy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.soapy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.soapy }}</div>
                                <div class="btn">{{ scope.row.soapy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="solvent" prop="solvent" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.solvent}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.solvent }}</div>
                                <div class="btn">{{ scope.row.solvent }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="sour" prop="sour" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.sour}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.sour }}</div>
                                <div class="btn">{{ scope.row.sour }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="spicy" prop="spicy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.spicy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.spicy }}</div>
                                <div class="btn">{{ scope.row.spicy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="strawberry" prop="strawberry" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.strawberry}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.strawberry }}</div>
                                <div class="btn">{{ scope.row.strawberry }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="sulfurous" prop="sulfurous" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.sulfurous}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.sulfurous }}</div>
                                <div class="btn">{{ scope.row.sulfurous }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="sweaty" prop="sweaty" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.sweaty}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.sweaty }}</div>
                                <div class="btn">{{ scope.row.sweaty }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="sweet" prop="sweet" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.sweet}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.sweet }}</div>
                                <div class="btn">{{ scope.row.sweet }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="tea" prop="tea" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.tea}`" placement="top-start">
                                <div slot="content">{{ scope.row.tea }}</div>
                                <div class="btn">{{ scope.row.tea }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="terpenic" prop="terpenic" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.terpenic}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.terpenic }}</div>
                                <div class="btn">{{ scope.row.terpenic }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="tobacco" prop="tobacco" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.tobacco}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.tobacco }}</div>
                                <div class="btn">{{ scope.row.tobacco }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="tomato" prop="tomato" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.tomato}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.tomato }}</div>
                                <div class="btn">{{ scope.row.tomato }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="tropical" prop="tropical" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.tropical}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.tropical }}</div>
                                <div class="btn">{{ scope.row.tropical }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="vanilla" prop="vanilla" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.vanilla}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.vanilla }}</div>
                                <div class="btn">{{ scope.row.vanilla }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="vegetable" prop="vegetable" min-width="150">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.vegetable}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.vegetable }}</div>
                                <div class="btn">{{ scope.row.vegetable }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="vetiver" prop="vetiver" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.vetiver}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.vetiver }}</div>
                                <div class="btn">{{ scope.row.vetiver }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="violet" prop="violet" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.violet}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.violet }}</div>
                                <div class="btn">{{ scope.row.violet }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="warm" prop="warm" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.warm}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.warm }}</div>
                                <div class="btn">{{ scope.row.warm }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="waxy" prop="waxy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.waxy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.waxy }}</div>
                                <div class="btn">{{ scope.row.waxy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="weedy" prop="weedy" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.weedy}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.weedy }}</div>
                                <div class="btn">{{ scope.row.weedy }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="winey" prop="winey" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.winey}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.winey }}</div>
                                <div class="btn">{{ scope.row.winey }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="woody" prop="woody" min-width="100">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="` ${scope.row.woody}`"
                                placement="top-start">
                                <div slot="content">{{ scope.row.woody }}</div>
                                <div class="btn">{{ scope.row.woody }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!--===分页=====-->
        <el-pagination :current-page="pageinfo.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageinfo.size"
            layout="total, sizes, prev, pager, next, jumper" :total="pageinfo.total" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" style="margin-bottom: 3px; text-align: center;"></el-pagination>
    </div>
</template>

<script>
export default {
    name: "odortypeSearchView",
    props: ['inputVal', 'requestUrl', 'odortypesearchViewActive',],
    data() {
        return {
            // 页面要显示的表格数据
            tableData: [],
            // 分页信息
            pageinfo: {
                page: 1,
                size: 10,
                total: 0,
            },
        };
    },
    created() {
    },
    methods: {
        senddata() {
            console.log(this.requestUrl)
            console.log(this.inputVal)
            console.log(this.pageinfo.size)
            console.log(this.pageinfo.page)
            this.$axios.get(this.requestUrl,
                {
                    params: {
                        search: this.inputVal,
                        page: this.pageinfo.page,
                        page_size: this.pageinfo.size,
                    }
                }
            ).then((response) => {
                console.log(response)
                this.tableData = response.data.data;
                console.log(this.tableData)
                this.pageinfo.total = response.data.count; // 总记录数
                this.pageinfo.size = parseInt(response.data.size);
                this.pageinfo.page = response.data.page;
            })
                .catch(resp => {
                    console.log("请求失败：" + resp.status + "," + resp.statusText);
                });
        },
        /** 切换每页显示条数 */
        handleSizeChange(val) {
            this.pageinfo.size = val;
            this.senddata();
        },
        /** 切换分页 */
        handleCurrentChange(val) {
            this.pageinfo.page = val;
            this.senddata();
        },
    },
};
</script>

<style>
/* 基本表格样式 */
/* 滚动条的容器 */
.table-box::-webkit-scrollbar {
    height: 13px;
    /* 滚动条的高度 */
}

/* 滚动条的滑块 */
.table-box::-webkit-scrollbar-thumb {
    background-color: #4091ff;
    /* 滚动条滑块的颜色 */
    border-radius: 4px;
    /* 滑块的圆角 */
}

.el-tooltip__popper.is-dark {
    display: block;
}

.el-table__header {
    table-layout: fixed;
}

.el-table {
    table-layout: fixed;
}

/* 表格头部样式 */
.el-table__header th {
    background: #f5f7fa;
    /* 表格头部背景色 */
    color: #409eff;
    /* 表格头部字体颜色 */
    text-align: center;
    /* 表格头部内容左对齐 */
    padding: 8px;
    /* 表格头部单元格内边距 */
    border: 1px solid #ddd;
    /* 表格头部边框 */
    border-bottom: 2px solid #ddd;
    /* 加粗底部边框 */
    white-space: nowrap;
    /* 文本不换行 */
}


/* 表格单元格样式 */
.el-table__row td {
    border-right: 1px solid #ddd;
    /* 单元格右侧边框 */
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    /* 单元格底部边框 */
    text-align: center;
    /* 单元格内容居中对齐 */
    padding: 8px 10px;
    /* 单元格内边距 */
    max-height: 50px;
    /* 设置最大高度 */
    overflow: hidden;
    /* 超出部分隐藏 */
    word-break: break-word;
    /* 允许长单词在单元格内换行 */
    overflow-wrap: break-word;
    /* 允许内容在单元格内换行 */
    line-height: 1.2;
    /* 根据需要调整行高 */
    text-overflow: ellipsis
}

/* 鼠标悬停时的行样式 */
.el-table__row:hover {
    background-color: #f5f7fa;
    /* 鼠标悬停时行的背景色 */
}

.el-table__row {
    height: 50px;
}

/* 分页组件的样式 */
.el-pagination {
    margin-top: 10px;
    /* 分页组件与表格的间距 */
    margin-left: 150px;
}


/* 下拉列表选项悬浮状态 */
.el-select-dropdown__item:hover {
    background-color: #f5f7fa;
    /* 鼠标悬停时选项的背景色 */
}

/* 下拉列表选项被选中状态 */
.el-select-dropdown__item.selected {
    color: #4480db;
    /* 选中文字颜色 */
    font-weight: bold;
    /* 加粗选中文字 */
}

/* 确保选中的下拉选项具有蓝色背景和字体颜色 */
.el-select-dropdown__item.selected:hover {
    background-color: #e6f1fc;
    /* 选中选项悬浮时的背景色 */
}


.el-tooltip__popper.is-dark {
    background-color: #f5f7fa !important;
    padding: 8px, 10px;
    color: #080000 !important;
    border: 10px solid #f5f7fa !important;
    max-height: 1200px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12) !important;
}

.el-tooltip__popper.is-dark .popper__arrow {
    display: none;
}

.btn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.el-input--mini {
    border-radius: 20px;
    /* 添加圆角边框 */
    border: 1px solid #dcdfe6;
    /* 边框颜色 */
    box-shadow: 0px 1px 0px rgba(0., 0, 0, 0.15);
}
</style>