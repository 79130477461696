<template>
  <div class="innerView">
    <div v-if="isAlertVisible" class="custom-alert">
      <p>Please enter at least one character</p>
      <button @click="closeAlert">close</button>
    </div>
    <div class="container" >
      <div id="articlebox" style="display: none;">
        <div class="buttons" style="position: relative; padding-bottom: 15px;">
        <el-button type="primary" plain @click="returnmain()" id="returnmain">return</el-button>
        <el-button type="primary" plain id="download" @click="downloadExcel">download</el-button>
      </div>
        <div class="table" style="padding-bottom: 15px;">       
          <SearchView :requestUrl.sync="requestUrl" :inputVal.sync="inputVal" :searchViewActive.sync="searchViewActive" 
            ref="searchview" ></SearchView>
          <pairSearchView :requestUrl.sync="requestUrl" :inputVal.sync="inputVal" :pairsearchViewActive.sync="pairsearchViewActive"
            ref="pairsearchview"></pairSearchView>
            <receptorSearchView :requestUrl.sync="requestUrl" :inputVal.sync="inputVal" :receptorsearchViewActive.sync="receptorsearchViewActive"
            ref="receptorsearchview"></receptorSearchView>
            <odortypeSearchView :requestUrl.sync="requestUrl" :inputVal.sync="inputVal" :odortypesearchViewActive.sync="odortypesearchViewActive"
            ref="odortypesearchview"></odortypeSearchView>        
 </div>
      </div>
      <div class="right-container" style="display: block;">
        <div class="title">
          <span>Query method</span>
          <div class="searchicon">
            <img src="../assets/picture/searchtubiao.png" alt="Logo" id="Logo" style="width: 30rem; height: auto;">
          </div>
        </div>
        <div class="query-options">
          <button class="single-query active">
            <svg t="1724072528595" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="6460" id="mx_n_1724072528596" width="30" height="30">
              <path
                d="M907.4 906.4c-29 29-76 29-105 0L657.7 761.7c-57.6 38.4-126.8 60.9-201.3 60.9-200.5 0-363.1-162.6-363.1-363.2S255.9 96.2 456.5 96.2s363.2 162.6 363.2 363.2c0 72.4-21.2 139.9-57.7 196.5l145.5 145.5c28.9 29 28.9 76-0.1 105zM456.4 231C330.3 231 228 333.3 228 459.4c0 126.1 102.3 228.4 228.4 228.4s228.4-102.3 228.4-228.4C684.9 333.3 582.6 231 456.4 231z m118.1 379.4c-1.4 2.1-3.5 3.6-6.2 4.2-5.5 1.3-11.1-2.2-12.3-7.7-1.2-5.2 1.7-10.3 6.7-12 59-46.5 80.8-126.4 53.6-196.1-0.7-1.4-1-2.9-1-4.5 0-5.7 4.6-10.3 10.3-10.3 4.2 0 7.8 2.5 9.4 6.1h0.1c30.7 78.3 6.1 168.4-60.6 220.3z"
                fill="#477fd3" p-id="6461"></path>
            </svg>
            <span>Single retrieval</span>
          </button>
          <button class="batch-query">
            <svg t="1724075093627" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="11079" width="25" height="25">
              <path
                d="M1024 708.7c0 36.4-20.5 69.6-53.1 85.9 0 0-261.1 130.3-374.9 186.8-23.9 11.9-52-5.5-52-32.3V456.4c0-20.3 12.7-38.4 31.8-45.2l415.9-148.6c15.6-5.6 32.1 6 32.1 22.6 0.2 105.9 0.2 423.5 0.2 423.5zM480 456.2v490.4c0 27.2-29.1 44.6-53 31.7L50.6 776.2C19.4 759.4 0 726.9 0 691.6c0 0 0.1-304.3 0.3-408 0-16.6 16.5-28.2 32.1-22.5L448.3 411c19 6.9 31.7 24.9 31.7 45.2zM978.9 199.2L544.5 354.4c-21 7.5-43.9 7.5-64.9-0.1L47.5 198.5c-9.4-3.4-10.8-16.1-2.4-21.4 5.4-3.3 11.1-6.2 17.2-8.5l416.1-156c10.9-4.1 22.3-6.1 33.7-6.1 11.4 0 22.7 2 33.5 6.1l415.9 155.1c7.2 2.7 13.9 6.2 20.1 10.3 8.1 5.5 6.5 17.9-2.7 21.2z"
                p-id="11080" fill="#477fd3"></path>
            </svg>
            <span>Bulk retrieval</span>
          </button>
        </div>
      </div>
      <div class="left-container" style="display: block;">
        <div class="single-result" style="display: block;">
          <div class="top">
            <img src="../assets/picture/fosu.png" alt="Logo" style="width: 17.5rem; height: auto;">
          </div>
          <div class="mid">
            <span style="color:black;font-size: 25px;">HELLO,MAKE!</span><br>
            <span style="color:black;font-weight:bolder; font-size: 30px;"> Food Aroma Molecular Signature Database</span>
          </div>
          <div class="mainsearch" style="position: absolute; top: 350px;">
            <div style="margin-left: 200px;">
              <el-input id="search" placeholder="  Please enter what you want to search for" v-model="inputVal" class="input-with-select"
                @keyup.enter.native="handleSearch">
                <el-select id="searchk" v-model="select" slot="prepend">
                  <el-option label="Odorant Database" value="1"></el-option>
                  <el-option label="Olfactory Receptor (OR) Database" value="2"></el-option>
                  <el-option label="Odorant-OR Response Database" value="3"></el-option>
                  <el-option label="Odorant OdorType Database" value="4"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="handleSearch()"></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="batch-result" style="display: none;">
          <div class="top">
            <img src="../assets/picture/fosu.png" alt="Logo" style="width: 17.5rem; height: auto;">
          </div>
          <div class="mid">
            <span style="color:black;font-size: 25px;">HELLO,MAKE!</span><br>
            <span style="color:black;font-weight:bolder; font-size: 30px;"> Food Aroma Molecular Signature Database</span>
          </div>
          <div class="mainsearch" style="position: absolute; top: 350px;">
            <div style="margin-left: 200px;">
              <el-input id="search" placeholder="  Please enter what you want to search for" v-model="inputVal" class="input-with-select"
                @keyup.enter.native="handleSearch">
                <el-select id="searchk" v-model="select" slot="prepend">
                 <el-option label="Odorant Database" value="1"></el-option>
                  <el-option label="Olfactory Receptor (OR) Database" value="2"></el-option>
                  <el-option label="Odorant-OR Response Database" value="3"></el-option>
                  <el-option label="Odorant OdorType Database" value="4"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="handleSearch()"></el-button>
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
* {
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
}
body {
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
#returnmain {
  position: relative;
  top: 30px;
  left: 4%;
  display: flex !important; 
  font-size: medium;
  text-align: center;
}
#download{
  position: relative;
  left: 88%;
  display: flex !important; 
  font-size: medium;
  text-align: center;
}
#Logo{
  position: relative;
  left:60%
}
.table {
  position: relative;
  display: flex;
  top: 30%;
  left: 4%
}
.container {
  width: 1400px;
  margin: 0 auto;
  margin-top: 2%;
  border-radius: 15px;
  box-shadow: 0 10px 5px 0px rbg(59, 45, 159);
  background-color: #fff;
  transition: height 0.3s ease;
  align-items: center;
  justify-content: center;
}
.right-container {
  display: inline-block;
  width: calc(100% - 1050px);
  /* 保持宽度不变 */
  height: 750px;
  border-top-right-radius: 15px;
  /* 将左上角的圆角改为右上角 */
  border-bottom-right-radius: 15px;
  /* 将左下角的圆角改为右下角 */
  padding: 60px;
  background-color: #b9dcfa;
  margin-left: auto;
  /* 将盒子推到右边 */
  float: right;
  /* 或者使用float属性 */
}
.left-container {
  background-image: url(../assets/picture/beijing.webp);
  width: 1050px;
  /* 根据需要调整 */
  height: 750px;
  /* 根据需要调整 */
}
.custom-alert {
  position: absolute;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 50px auto;
  text-align: center;
  z-index: 4000;
  top: -6%;
  /* 举例，根据需要调整 */
  left: 50%;
  /* 并使用 left: 50%` 与 `transform` 居中显示 */
  transform: translateX(-50%);
  /* 向左移动50%，以实现水平居中 */
}
.custom-alert p {
  margin: 0;
  color: #cd2e23;
}
.custom-alert button {
  margin-top: 10px;
  padding: 5px 15px;
  background-color: #4962be;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.title {
  color: #477fd3;
  font-size: 25px;
  font-weight: 200;
  font-weight: bolder;
  position: relative;
  top: 20%;
}
.title span {
  border-bottom: 8px solid #fff;
}
.input-container {
  padding: 20px 0;
}
::-webkit-input-placeholder {
  color: rgb(199, 191, 219);
}
.top {
  position: absolute;
  top: 20px;
  left: 40px;
}
.mid {
  position: absolute;
  top: 200px;
  left: 200px;
  text-align: center;
}
.mid span {
  line-height: 2;
}
.searchicon {
  position: absolute;
  top: -2rem;
  left: 8rem;
}
.picture {
  position: absolute;
  left: 900px;
  top: -30px;
}
.query-options {
  margin-top: 200px;
  margin-left: -62px;
}
.single-query .icon {
  margin-right: 8px;
  /* 图标和文本之间的间距 */
}
.batch-query .icon {
  margin-right: 8px;
  /* 图标和文本之间的间距 */
}
.query-options button {
  margin: 0px 10px 50px 0;
  padding: 10px 10px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  width: 300px;
  border: 2px;
  border-radius: 5px;
  color: #477fd3;
  background-color: transparent;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}
.query-options button .icon path {
  fill: #477fd3;
}
.query-options button:hover .icon path {
  fill: black;
}
.query-options button.active .icon path {
  fill: black;
}
.query-options button:hover {
  border-color: #477fd3;
  border: 2px solid #477fd3;
  border-radius: 5px;
  background-color: #fff;
  transition: border-color 0.3s ease;
  color: #252628 !important;
}
.active {
  border-color: #fff;
  /* 被点击时边框颜色变为粉色 */
  background-color: #fff !important;
  color: black !important;
}
.el-select .el-input {
  width: 150px;
}
.input-with-select .el-input-group__prepend {
  background-color: #477fd3;
  border-radius: 50px;
}
.el-input__inner {
  background-color: transparent !important;
}
.input-with-select {
  width: 700px;
  left: -30px;
  z-index: 1000;
}
#searchk.el-input__inner::placeholder {
  color: #fff !important;
  font-weight: bold;
  text-align: center;
}
#searchk.el-input__inner {
  color: #fff !important;
  font-weight: bold;
  text-align: center;
}
.el-select__caret {
  color: #fff !important;
}
#search {
  border-color: #4480db !important;
}
.el-input-group__append {
  background-color: #4480db !important;
  border: 3px solid #4480db !important;
  border-radius: 50px;
}
.el-input-group__append,
.el-input-group__prepend {
  color: #fff !important;
}
.input-with-select.el-input.el-input-group.el-input-group--append.el-input-group--prepend {
  border: 3px solid #4480db !important;
  border-radius: 50px;
  padding: 10px;
}
.el-icon-search {
  position: absolute;
  top: 10px;
  left: 13px;
}
</style>
<script>
import 'element-ui/lib/theme-chalk/index.css'
import SearchView from './odorantdataView.vue';
import pairSearchView from './pairSearchView.vue';
import receptorSearchView from './receptorSearchView.vue';
import odortypeSearchView from './odortypeSearchView.vue';
import * as XLSX from 'xlsx';
export default {
  components: {
    SearchView,
    pairSearchView,
    receptorSearchView,
    odortypeSearchView,
  },
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      select: '1',
      inputVal: '',
      requestUrl: '',
      searchViewActive: false,
      pairsearchViewActive: false,
      receptorsearchViewActive: false,
      odortypesearchViewActive: false,
      isAlertVisible: false,
      searchType: 'single',
    };
  },
  methods: {
    open3() {
        this.$message({
          showClose: true,
          message: '单次下载数据不能超过20条',
          type: 'warning'
        });
      },
    handleSearch() {
      this.inputVal = String(this.inputVal).trim();
      console.log(this.inputVal);
      if (!this.inputVal) {
        this.isAlertVisible = true;
        return;
      } else {
        console.log(this.searchType)
        // 根据选择的数据库设置基础URL
        let baseUrl = '';
        switch (this.select) {
          case '1':
            baseUrl = 'https://famsmol.atomevo.com/Database/date/';
            break;
          case '2':
            baseUrl = 'https://famsmol.atomevo.com/Database/receptor/';
            break;
          case '3':
            baseUrl = 'https://famsmol.atomevo.com/Database/pair/';
            break;
          case '4':
            baseUrl = 'https://famsmol.atomevo.com/Database/odortype/';
            break;
          default:
            baseUrl = 'https://famsmol.atomevo.com/Database/date/';
        }
        // 根据搜索类型添加额外的URL后缀
        if (this.searchType === 'batch') {
          baseUrl += 'more/'; // 批量搜索的特定后缀
        }
        this.requestUrl = baseUrl;
        console.log(this.requestUrl);

        this.$nextTick(() => {
          // 显示搜索结果容器，隐藏左右容器
          const leftContainer = document.querySelector('.left-container');
          const rightContainer = document.querySelector('.right-container');
          const searchArticleBox = document.getElementById('articlebox');
          searchArticleBox.style.display = 'block';
          leftContainer.style.display = 'none';
          rightContainer.style.display = 'none';
        });
        switch (this.select) {
          case '1':
            this.$nextTick(() => {
              if (this.$refs.searchview) {
                this.$refs.searchview.senddata();
              }
            });
            this.searchViewActive = true;
            this.pairsearchViewActive = false;
            this.receptorsearchViewActive = false;
            this.odortypesearchViewActive = false;
            break;
          case '2':
            this.$nextTick(() => {
              if (this.$refs.receptorsearchview) {
                this.$refs.receptorsearchview.senddata();
              }
            });
            this.receptorsearchViewActive = true;
            this.pairsearchViewActive = false;
            this.searchViewActive = false;
            this.odortypesearchViewActive = false;
            break;
          case '3':
            this.$nextTick(() => {
              if (this.$refs.pairsearchview) {
                this.$refs.pairsearchview.senddata();
              }
            });
            this.searchViewActive = false;
            this.pairsearchViewActive = true;
            this.receptorsearchViewActive = false;
            this.odortypesearchViewActive = false;
            break;
          case '4':
          this.$nextTick(() => {
              if (this.$refs.odortypesearchview) {
                this.$refs.odortypesearchview.senddata();
              }
            });
            this.searchViewActive = false;
            this.pairsearchViewActive = false;
            this.receptorsearchViewActive = false;
            this.odortypesearchViewActive = true;
            break;
          default:
            this.$nextTick(() => {
              if (this.$refs.searchview) {
                this.$refs.searchview.senddata();
              }
            });
            this.searchViewActive = true;
            this.pairsearchViewActive = false;
            this.receptorsearchViewActive = false;
            this.odortypesearchViewActive = false;
        }
      }
    },
    closeAlert() {
      this.isAlertVisible = false; // 隐藏警告框
    },
    returnmain() {
      const searchArticleBox = document.getElementById('articlebox');
      const leftContainer = document.querySelector('.left-container');
      const rightContainer = document.querySelector('.right-container');

      searchArticleBox.style.display = 'none';
      leftContainer.style.display = 'block';
      rightContainer.style.display = 'block';
    },

    activateButton(activeBtn, otherBtn) {
      otherBtn.classList.remove('active');
      activeBtn.classList.add('active');
    },
    showResult(toShow, toHide) {
      toShow.style.display = 'block';
      toHide.style.display = 'none';
    },
   downloadExcel() {
  // 根据选择的数据库设置基础URL
   let baseUrl = '';
   switch (this.select) {
    case '1':
      baseUrl = 'https://famsmol.atomevo.com/Database/date/'; 
      break;
    case '2':
      baseUrl = 'https://famsmol.atomevo.com/Database/receptor/'; 
      break;
    case '3':
      baseUrl = 'https://famsmol.atomevo.com/Database/pair/'; 
      break;
    case '4':
      baseUrl = 'https://famsmol.atomevo.com/Database/odortype/'; 
      break;
    default:
      baseUrl = 'https://famsmol.atomevo.com/Database/date/'; 
  }
  if (this.searchType === 'batch') {
          baseUrl += 'more/'; // 批量搜索的特定后缀
        }
   // 添加/download/后缀以获取全部数据
   let downloadUrl = baseUrl + 'download/';
   // 发起请求以获取数据
   this.$axios.get(downloadUrl ,
        {
          params: {
            search: this.inputVal,
          }
        }
      )
     .then((response) => {
      console.log(response)
      let arrayData;
        // 检查数据是否为数组
     if (!Array.isArray(response.data.data)) {
      // 如果不是数组，尝试转换为数组
      // 假设data.data是一个类数组对象，如NodeList
        arrayData = Array.from(response.data.data) || [];
      } else {
      // 如果已经是数组，直接使用
         arrayData = response.data.data;
      }
      // 假设后端返回的数据格式是数组，每个元素都是一行数据
      const worksheet = XLSX.utils.json_to_sheet(arrayData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'table_data.xlsx');
    })
    .catch(resp => {
          console.log("请求失败：" + resp.status + "," + resp.statusText);
     });
   }
  },
  mounted() {
    // 使用箭头函数确保 this 指向 Vue 实例
    this.$nextTick(() => {
      const singleQueryBtn = this.$el.querySelector('.single-query');
      const batchQueryBtn = this.$el.querySelector('.batch-query');
      const singleResultDiv = this.$el.querySelector('.single-result');
      const batchResultDiv = this.$el.querySelector('.batch-result');
      singleQueryBtn.addEventListener('click', () => {
        this.activateButton(singleQueryBtn, batchQueryBtn);
        this.showResult(singleResultDiv, batchResultDiv);
        this.searchType = 'single';
      });
      batchQueryBtn.addEventListener('click', () => {
        this.activateButton(batchQueryBtn, singleQueryBtn);
        this.showResult(batchResultDiv, singleResultDiv);
        this.searchType = 'batch';
      });
    });    
  },
}
</script>